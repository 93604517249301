import React, { useEffect, useState } from "react"
import speakupByJlo from "assets/images/speakupLogoByJlo.svg"
import pimeoByJlo from "assets/images/pimeoLogoByJlo.svg"
import leaByJlo from "assets/images/leaLogoByJlo.svg"
import * as Styles from "./ContentWithSpeakupLogo.styles"
import { AnimatePresence } from "framer-motion"

export const ContentWithSpeakupLogo: React.FC = (props) => {
  const logos = [ speakupByJlo, pimeoByJlo, leaByJlo ]
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ showLogo, setShowLogo ] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setShowLogo(false)
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length)
        setShowLogo(true)
      }, 300)
    }, 2000)

    return () => clearInterval(interval)
  }, [ logos.length ])

  return (
    <Styles.Container>
      <Styles.AnimatePresenceContainer>
        <AnimatePresence>
          {showLogo && (
            <Styles.ScrollingContainer
              key={currentIndex}
              initial={{ opacity: 0, x: "100%" }}
              animate={{ opacity: 1, x: "0" }}
              exit={{ opacity: 0, x: "-100%" }}
              transition={{ duration: 0.3 }}
            >
              <Styles.Logo src={logos[currentIndex]} alt="" />
            </Styles.ScrollingContainer>
          )}
        </AnimatePresence>
      </Styles.AnimatePresenceContainer>
      {props.children}
    </Styles.Container>
  )
}
